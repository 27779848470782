import { Color } from 'types/shared';

import { capitalizeFirstLetter } from 'core/shared';
import { getTranslations } from 'hooks/useTranslations';

import { Driver, Reservation, Transaction, TravelCardStatus } from 'useQuery/types';

import { customColors } from './customColors';

interface GetLabelColorProps {
  transaction?: Transaction['status'];
  driver?: Driver['status'];
  travelCard?: TravelCardStatus;
  provider?: Reservation['provider'];
  reservationStatus?: Reservation['status'];
  reservationType?: Reservation['type'];
}

function getLabelColor({
  transaction,
  driver,
  travelCard,
  provider,
  reservationStatus,
  reservationType,
}: GetLabelColorProps): Color {
  if (transaction) {
    switch (transaction) {
      case 'COMPLETED':
        return 'success.main';
      case 'PENDING':
        return 'warning.main';
      case 'PUBLIC_TRANSPORT':
        return 'success.main';
      case 'SKIPPED':
        return 'gray';
    }
  }

  if (driver) {
    switch (driver) {
      case 'ACTIVATED':
        return 'success.main';
      case 'PENDING':
        return 'gray';
      case 'DEACTIVATED':
        return 'error.main';
      case 'INVITED':
        return 'warning.main';
    }
  }

  if (travelCard) {
    switch (travelCard) {
      case 'active':
        return 'success.main';
      case 'closed':
        return 'error.main';
      case 'suspended':
        return 'gray';
    }
  }

  if (provider) {
    switch (provider) {
      case 'Check':
        return customColors.check as Color;
      case 'Felyx':
        return customColors.felyx as Color;
      case 'Vecore':
        return customColors.vecore as Color;
      case 'Greenwheels':
        return customColors.greenwheels as Color;
      case 'MyWheels':
        return customColors.mywheels as Color;
      case 'Amber':
        return customColors.amber as Color;
    }
  }

  if (reservationStatus) {
    switch (reservationStatus) {
      case 'cancelled':
        return 'error.main';
      case 'started':
        return 'success.main';
      case 'active':
        return 'success.light';
      case 'future':
        return 'info.main';
      case 'past':
        return 'gray';
    }
  }

  if (reservationType) {
    switch (reservationType) {
      case 'BUSINESS':
        return 'error.light';
      case 'PRIVATE':
        return 'success.light';
    }
  }

  return 'primary.main';
}

interface GetTransactionStatusProps {
  transaction?: Transaction['status'];
  driver?: Driver['status'];
  travelCard?: TravelCardStatus;
}

function getStatus({ transaction, driver, travelCard }: GetTransactionStatusProps): string {
  const translations = getTranslations('transactionStatus');

  if (transaction) {
    switch (transaction) {
      case 'PENDING':
        return translations.pending;
      case 'COMPLETED':
        return translations.uploaded;
      case 'SKIPPED':
        return translations.skipped;
      case 'PUBLIC_TRANSPORT':
        return translations.publicTransport;
      default:
        console.error(
          `Transaction status is missing in getStatus(...), got: ${transaction} expected type Transaction['status']`,
        );
        return capitalizeFirstLetter((transaction as string).replaceAll('_', ' ').toLowerCase());
    }
  }

  if (driver) {
    switch (driver) {
      case 'ACTIVATED':
        return translations.activated;
      case 'PENDING':
        return translations.pending;
      case 'DEACTIVATED':
        return translations.deactivated;
      case 'INVITED':
        return translations.invited;
      default:
        console.error(
          `driver status is missing in getStatus(...), got: ${driver} expected type Driver['status']`,
        );
        return capitalizeFirstLetter((driver as string).replaceAll('_', ' ').toLowerCase());
    }
  }

  if (travelCard) {
    switch (travelCard) {
      case 'active':
        return translations.active;
      case 'closed':
        return translations.closed;
      case 'suspended':
        return translations.suspended;
      default:
        console.error(
          `driver status is missing in getStatus(...), got: ${travelCard} expected type TravelCardStatus`,
        );
        return capitalizeFirstLetter((travelCard as string).replaceAll('_', ' ').toLowerCase());
    }
  }

  return '';
}

type GetAppEnviromentReturn = 'PRODUCTION' | 'PREVIEW' | 'TEST' | 'DEVELOPMENT';

function getAppEnviroment(): GetAppEnviromentReturn {
  // eslint-disable-next-line no-restricted-properties
  switch (process.env.REACT_APP_APP_ENV) {
    case 'production':
      return 'PRODUCTION';
    case 'preview':
      return 'PREVIEW';
    case 'test':
      return 'TEST';
    case 'development':
      return 'DEVELOPMENT';
    default:
      return 'DEVELOPMENT';
  }
}

function getSuperAdminDashboardUrl(): string {
  return process.env.REACT_APP_SUPER_ADMIN_DASHBOARD_URL as string;
}

export { getAppEnviroment, getLabelColor, getStatus, getSuperAdminDashboardUrl };
