import { Unstable_Grid2 as Grid } from '@mui/material';
import dayjs from 'dayjs';

import { Container, LineChart } from 'components';
import { useTranslations } from 'hooks/useTranslations';

import { UseQuery } from 'useQuery';

export function ConsumerStatistics() {
  const { data: consumerStatisticsData } = UseQuery.consumerStatistics();
  const translations = useTranslations('statisticsPage');

  return (
    <Grid container>
      <Container
        title={translations.consumers}
        width="flex"
        loading={consumerStatisticsData === undefined}
      >
        <LineChart
          labels={Object.keys(consumerStatisticsData?.result ?? []).map((key) =>
            dayjs(key).format('DD/MM'),
          )}
          data={[
            {
              label: translations.numberOfUsers,
              values: Object.keys(consumerStatisticsData?.result ?? [])
                .map((key) => consumerStatisticsData?.result[key])
                .filter((item) => typeof item === 'number') as number[],
            },
          ]}
          colors={['info.main']}
        />
      </Container>
    </Grid>
  );
}
