import React from 'react';

import { InformationBlock } from 'components';
import { useToast } from 'core/toast/hooks';
import { useTranslations } from 'hooks/useTranslations';

import { UseMutation, UseQuery } from 'useQuery';

interface OrganisationProvidersProps {
  id: string;
  providersData: { [key: string]: object };
}

interface NSProps {
  agreement: string;
  department: string;
  company?: string;
}

function OrganisationProviders({ id, providersData }: OrganisationProvidersProps) {
  const translations = useTranslations('organisationPage');
  const { addToast } = useToast();

  const [agreement, setAgreement] = React.useState<string>();
  const [department, setDepartment] = React.useState<string>();
  const [company, setCompany] = React.useState<string>();

  const NSData = providersData['ns'] as NSProps;

  const { refetch: refetchOrganisationData, isLoading: loadinhOrganisationData } =
    UseQuery.organisation({ id });
  const {
    mutateAsync: mutateAsyncUpdateOrganisationProviders,
    isLoading: loadingUpdateOrganisationProviders,
  } = UseMutation.updateOrganisationProviders();

  const disabledSave =
    (NSData?.agreement === agreement &&
      NSData?.department === department &&
      NSData?.company === company) ||
    agreement === ('' || undefined) ||
    department === ('' || undefined) ||
    !!loadingUpdateOrganisationProviders ||
    !!loadinhOrganisationData;

  React.useEffect(() => {
    setAgreement(NSData?.agreement);
    setDepartment(NSData?.department);
    setCompany(NSData?.company ?? '');
  }, [providersData]);

  async function updateProviders() {
    if (agreement === ('' || undefined) || department === ('' || undefined)) {
      return;
    }

    try {
      await mutateAsyncUpdateOrganisationProviders({
        id,
        providers: {
          NS: {
            agreement: agreement === '' ? undefined : agreement,
            department: department === '' ? undefined : department,
            company: company === '' ? undefined : company,
          },
        },
      });
      await refetchOrganisationData();
      addToast({ title: translations.providerUpdatedSuccesfully, severity: 'success' });
    } catch (error) {
      addToast({ error, severity: 'error' });
    }
  }

  return (
    <InformationBlock<string>
      title={translations.NSBusinessCard}
      key={id}
      footer={[
        {
          label: translations.save,
          onClick: updateProviders,
          loading: loadingUpdateOrganisationProviders,
          disabled: disabledSave,
        },
      ]}
      items={[
        {
          data: {
            value: agreement ?? '',
            onChange: setAgreement,
            onEnter: () => {},
            required: true,
          },
          label: translations.agreement,
          type: 'editText',
        },
        {
          data: {
            value: department ?? '',
            onChange: setDepartment,
            onEnter: () => {},
            required: true,
          },
          label: translations.department,
          type: 'editText',
        },
        {
          data: {
            value: company ?? '',
            onChange: setCompany,
            onEnter: () => {},
            required: false,
          },
          label: translations.company,
          type: 'editText',
        },
      ]}
    />
  );
}

export { OrganisationProviders };
