import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Line } from 'react-chartjs-2';
import { Box, useTheme } from '@mui/material';
import { Color } from 'types/shared';

import { getColorFromTheme, getColorRangeFromTheme } from 'core/color';
import { useDarkMode } from 'core/darkMode/hooks';
import { convertRemToPixel } from 'core/shared';
import { useHasMobileView } from 'hooks/useHasMobileView';

import { CustomColor } from 'config/customColors';

type LineChartProps =
  | {
      labels: string[];
      data: {
        label: string;
        values: number[];
      }[];
      colors?: undefined;
    }
  | {
      labels: string[];
      data: {
        label: string;
        values: number[];
      }[];
      colors: (Color | CustomColor)[];
    };

function LineChart({ labels, data, colors }: LineChartProps) {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const [backgroundColors, setBackgroundColors] = React.useState<string[]>([]);
  const [borderColors, setBorderColors] = React.useState<string[]>([]);

  const { hasMobileView } = useHasMobileView();

  React.useEffect(() => {
    if (colors !== undefined) {
      if (colors.length !== data.length) {
        console.error('LineChart: backgroundColors.length !== data.length');
        console.info('colors', colors);
        console.info('data', data);
      } else {
        setBackgroundColors(
          colors.map((color) => getColorFromTheme({ color, alpha: 0.85, theme })),
        );

        setBorderColors(colors.map((color) => getColorFromTheme({ color, alpha: 1, theme })));
        return;
      }
    }

    setBackgroundColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 0.85,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );
    setBorderColors(
      getColorRangeFromTheme({
        amount: data.length,
        alpha: 1,
        mode: data.length > 3 ? 'successInfoWarningError' : 'primary',
        theme,
      }),
    );
  }, [data.length, colors]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: `calc(100vw - ${theme.spacing(11)})`,
        padding: 1,
        height: 300,
      }}
    >
      <Line
        height={300}
        data={{
          labels,
          datasets: data.map(({ label, values }, index) => ({
            label: label,
            data: values,
            backgroundColor: backgroundColors[index],
            borderColor: borderColors[index],
          })),
        }}
        plugins={[
          {
            id: 'legend',
            beforeInit: function (chart) {
              if (!chart.legend) {
                return;
              }
              const originalFit = (chart.legend as unknown as { fit: () => void }).fit;

              // Workaround to make the legend fit above the graph area
              // Override the fit function
              (chart.legend as unknown as { fit: () => void }).fit = function fit() {
                // Call the original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)();
                // Change the height as suggested in other answers
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.height += parseInt(
                  theme.spacing(chart.legend?.position !== 'right' && data.length > 1 ? 2 : 0),
                  10,
                );
              };
            },
          },
        ]}
        options={{
          font: {
            family: 'Muli',
          },
          animation: {
            duration: 1,
            onProgress: ({ chart }) => {
              const ctx = chart.ctx;

              const fillStyle =
                (chart.legend?.legendItems ?? [])[0].fontColor ??
                getColorFromTheme({ color: 'common.white', alpha: 1, theme });

              ctx.font = `bold 1rem ${theme.typography.fontFamily}`;
              ctx.fillStyle = fillStyle;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              if (!hasMobileView && data.length === 1) {
                chart.data.datasets.forEach((dataset, index) => {
                  const meta = chart.getDatasetMeta(index);
                  meta.data.forEach(({ x, y }, index) => {
                    const data = dataset.data[index];
                    ctx.fillText(`${data}`, x, y - 5);
                  });
                });
              }
            },
            onComplete: ({ chart }) => {
              const ctx = chart.ctx;

              const fillStyle =
                (chart.legend?.legendItems ?? [])[0].fontColor ??
                getColorFromTheme({ color: 'common.white', alpha: 1, theme });

              ctx.font = `bold 1rem ${theme.typography.fontFamily}`;
              ctx.fillStyle = fillStyle;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              if (!hasMobileView && data.length === 1) {
                chart.data.datasets.forEach((dataset, index) => {
                  const meta = chart.getDatasetMeta(index);
                  meta.data.forEach(({ x, y }, index) => {
                    const data = dataset.data[index];
                    ctx.fillText(`${data}`, x, y - 5);
                  });
                });
              }
            },
          },
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: parseInt(
                theme.spacing(data.length === 1 || (data.length > 1 && !hasMobileView) ? 3 : 0),
                10,
              ),
              left: parseInt(theme.spacing(2), 10),
              right: parseInt(theme.spacing(2), 10),
              bottom: 0,
            },
          },
          scales: {
            y: {
              grid: {
                drawTicks: true,
                display: true,
              },
              ticks: {
                display: hasMobileView || data.length !== 1,
                color: getColorFromTheme({
                  color: mode === 'light' ? 'common.black' : 'common.white',
                  theme,
                }),
              },
              border: {
                width: 0,
              },
            },
            x: {
              grid: {
                drawTicks: true,
                display: false,
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: convertRemToPixel(1),
                },
                color: getColorFromTheme({
                  color: mode === 'light' ? 'common.black' : 'common.white',
                  theme,
                }),
              },
              border: {
                width: 0,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: data.length > 1,
              position: hasMobileView ? 'top' : 'right',

              labels: {
                padding: parseInt(theme.spacing(hasMobileView ? 2 : 3), 10),
                font: {
                  family: theme.typography.fontFamily,
                  size: convertRemToPixel(1),
                },
              },
            },
          },
          color: getColorFromTheme({
            color: mode === 'light' ? 'common.black' : 'common.white',
            theme,
          }),
        }}
      />
    </Box>
  );
}

export { LineChart };
