import { BaseRoute, CustomRoute } from 'types/navigation';

import { SVGName } from 'components';
import { getTranslations } from 'hooks/useTranslations';

import {
  BalanceAccountsPage,
  CommunitiesPage,
  CommunityPage,
  ConfigurationPage,
  DriverPage,
  DriversPage,
  InvitesPage,
  LoginPage,
  MainPage,
  MetaRulesPage,
  NotFoundPage,
  OrganisationPage,
  OrganisationsPage,
  PrivacyPage,
  ProductPage,
  ProductsPage,
  PushNotificationsPage,
  ReservationPage,
  ReservationsPage,
  StatisticsPage,
  TransactionPage,
  TransactionsPage,
  TravelCardsPage,
  VoucherPage,
  VouchersPage,
} from 'pages';

type Categories = 'Management' | 'Core' | 'Mobility Budget' | 'Sales' | 'Promotions';

type RoutePaths =
  | '/'
  | '/login'
  | '/statistics'
  | '/drivers'
  | '/transactions'
  | '/meta-rules'
  | '/configuration'
  | '/vouchers'
  | '/privacy'
  | '/reservations'
  | '/travelCards'
  | '/products'
  | '/organisations'
  | '/push-notifications'
  | '/balance-accounts'
  | '/communities'
  | '/invites'
  | '/not-found';

type RouteNames =
  | 'configuration'
  | 'login'
  | 'main'
  | 'statistics'
  | 'drivers'
  | 'driver'
  | 'transactions'
  | 'transaction'
  | 'metaRules'
  | 'voucher'
  | 'vouchers'
  | 'privacy'
  | 'reservation'
  | 'reservations'
  | 'travelCards'
  | 'product'
  | 'products'
  | 'organisation'
  | 'organisations'
  | 'pushNotifications'
  | 'balanceAccounts'
  | 'community'
  | 'communities'
  | 'invites'
  | 'notFound';

// If you encounter an TypeScript error in the CustomRoutes interface below
// the desired page configuration is incorrect
interface CustomRoutes extends Record<RouteNames, CustomRoute<Categories, RoutePaths>> {
  login: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: false;
    showMenuAndHeader: false;
    centerContent: true;
    svgName: undefined;
  };
  main: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  statistics: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  drivers: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  driver: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  transaction: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  transactions: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  metaRules: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  configuration: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  voucher: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  vouchers: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  privacy: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: false;
    showMenuAndHeader: false;
    centerContent: false;
    svgName: undefined;
  };
  reservation: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  reservations: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  travelCards: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  product: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  products: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  organisation: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  organisations: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  pushNotifications: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  balanceAccounts: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  community: BaseRoute<Categories, RoutePaths> & {
    hasId: true;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
  communities: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  invites: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: true;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: SVGName;
  };
  notFound: BaseRoute<Categories, RoutePaths> & {
    hasId: false;
    showInMenu: false;
    showMenuAndHeader: true;
    centerContent: undefined;
    svgName: undefined;
  };
}

const translations = getTranslations('route');

// If you encounter an TypeScript error in 'const hasCorrectKeys', the keys in 'interface CustomRoutes' do not match 'type RouteNames'.
const hasCorrectKeys: keyof CustomRoutes extends RouteNames ? true : false = true;
if (!hasCorrectKeys) {
  console.error("route.tsx: The keys in 'interface CustomRoutes' do not match 'type RouteNames'");
}

// If in you encounter a TypeScript error in 'const typeCheckRoutes', the typing declared in 'interface CustomRoutes' does not match 'const typeCheckRoutes' which is used for displaying content and menu items
const typeCheckRoutes: CustomRoutes = {
  login: {
    title: translations.login,
    path: '/login',
    showInMenu: false,
    showMenuAndHeader: false,
    centerContent: true,
    svgName: undefined,
    hasId: false,
    element: LoginPage,
    category: 'Management',
  },
  main: {
    title: translations.main,
    path: '/',
    hasId: false,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: MainPage,
    category: 'Management',
  },
  statistics: {
    title: translations.statistics,
    path: '/statistics',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'queryStats',
    element: StatisticsPage,
    category: 'Management',
  },
  drivers: {
    title: translations.drivers,
    path: '/drivers',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'group',
    element: DriversPage,
    category: 'Core',
  },
  driver: {
    title: translations.driver,
    path: '/drivers',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: DriverPage,
    category: 'Core',
  },
  transaction: {
    title: translations.transaction,
    path: '/transactions',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: TransactionPage,
    category: 'Mobility Budget',
  },
  transactions: {
    title: translations.transactions,
    path: '/transactions',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'shoppingBasket',
    element: TransactionsPage,
    category: 'Mobility Budget',
  },
  metaRules: {
    title: translations.metaRules,
    path: '/meta-rules',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'receiptLong',
    element: MetaRulesPage,
    category: 'Management',
  },
  configuration: {
    title: translations.configuration,
    path: '/configuration',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'settings',
    element: ConfigurationPage,
    category: 'Management',
  },
  voucher: {
    title: translations.voucher,
    path: '/vouchers',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: VoucherPage,
    category: 'Core',
  },
  vouchers: {
    title: translations.vouchers,
    path: '/vouchers',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'cardGiftcard',
    element: VouchersPage,
    category: 'Promotions',
  },
  privacy: {
    title: translations.privacy,
    path: '/privacy',
    hasId: false,
    showInMenu: false,
    showMenuAndHeader: false,
    centerContent: false,
    svgName: undefined,
    element: PrivacyPage,
    category: 'Core',
  },
  reservation: {
    title: translations.reservation,
    path: '/reservations',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: ReservationPage,
    category: 'Core',
  },
  reservations: {
    title: translations.reservations,
    path: '/reservations',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'eventNote',
    element: ReservationsPage,
    category: 'Core',
  },
  travelCards: {
    title: translations.travelCards,
    path: '/travelCards',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'creditCard',
    element: TravelCardsPage,
    category: 'Mobility Budget',
  },
  product: {
    title: translations.product,
    path: '/products',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: ProductPage,
    category: 'Sales',
  },
  products: {
    title: translations.products,
    path: '/products',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'shoppingCart',
    element: ProductsPage,
    category: 'Sales',
  },
  organisation: {
    title: translations.organisation,
    path: '/organisations',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: OrganisationPage,
    category: 'Core',
  },
  organisations: {
    title: translations.organisations,
    path: '/organisations',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'corporateFare',
    element: OrganisationsPage,
    category: 'Core',
  },
  pushNotifications: {
    title: translations.pushNotifications,
    path: '/push-notifications',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'notifications',
    element: PushNotificationsPage,
    category: 'Sales',
  },
  balanceAccounts: {
    title: translations.balanceAccounts,
    path: '/balance-accounts',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'accountBalance',
    element: BalanceAccountsPage,
    category: 'Mobility Budget',
  },
  community: {
    title: translations.community,
    path: '/communities',
    hasId: true,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: CommunityPage,
    category: 'Core',
  },
  communities: {
    title: translations.communities,
    path: '/communities',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'diversity3',
    element: CommunitiesPage,
    category: 'Core',
  },
  invites: {
    title: translations.invites,
    path: '/invites',
    hasId: false,
    showInMenu: true,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: 'email',
    element: InvitesPage,
    category: 'Promotions',
  },
  notFound: {
    title: translations.notFound,
    path: '/not-found',
    hasId: false,
    showInMenu: false,
    showMenuAndHeader: true,
    centerContent: undefined,
    svgName: undefined,
    element: NotFoundPage,
    category: 'Core',
  },
};

const customRoutes = {
  ...typeCheckRoutes,
} as CustomRoutes;

export { type CustomRoutes, customRoutes, type RouteNames, type RoutePaths };
