// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meta } from 'types/shared';

import { API } from 'core/api';
import { useCognitoUser } from 'core/cognito';
import { createNamedBlob, NamedBlob } from 'core/shared';
import { useToast } from 'core/toast/hooks';

interface ReceiptStatistics {
  COMPLETED?: number;
  PENDING?: number;
  SKIPPED?: number;
  PUBLIC_TRANSPORT?: number;
}

interface UseReceiptStatisticsProps {
  organisationId?: string;
  startPeriod?: string;
  endPeriod?: string;
}

function useReceiptStatistics({
  organisationId,
  startPeriod,
  endPeriod,
}: UseReceiptStatisticsProps) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: ReceiptStatistics;
    meta: Meta;
  }

  interface Params {
    organisation_id?: string;
    start_period: string;
    end_period: string;
  }

  function fetchData() {
    return API.get<Response, Params, false>({
      path: '/admin/statistics/receipts',
      token,
      params: {
        organisation_id: organisationId,
        start_period: startPeriod ?? '',
        end_period: endPeriod ?? '',
      },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(
    ['receiptStatistics', { organisationId, startPeriod, endPeriod }],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token && !!startPeriod && !!endPeriod,
      retry: 5,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

type ConsumerStatistics = {
  [date in string]: number;
};

function useConsumerStatistics() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  interface Response {
    result: ConsumerStatistics;
    meta: Meta;
  }

  function fetchData() {
    return API.get<Response, undefined, false>({
      path: '/admin/statistics/consumers',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<Response>, unknown, Response>(['consumerStatistics'], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 5,
    select: ({ data }) => data,
    keepPreviousData: true,
  });
}

interface MonthData {
  by_month: Record<
    string,
    Record<'CHECK' | 'VECORE' | 'FELYX' | 'MYWHEELS' | 'GREENWHEELS' | 'ADYEN' | 'REVENUE', number>
  >[];
  compound_growth_rate: number;
  forecasted: number;
  monthly_growth: number;
  total: number;
}

interface ReservationStatistics {
  top_days: { day: string; amount: number }[];
  bottom_days: { day: string; amount: number }[];
  completed: MonthData;
  cancelled: MonthData;
  spend: MonthData;
  reservation_types: {
    Business: number;
    Consumer: number;
    Private: number;
  };
}

function useReservationStatistics() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();
  function fetchData() {
    return API.get<ReservationStatistics, undefined, false>({
      path: '/admin/statistics/reservations',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<ReservationStatistics>, unknown, ReservationStatistics>(
    ['reservationStatistics'],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 5,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

interface ProviderStatistics {
  result: {
    [key: string]: {
      converted: number;
      pending: number;
    };
  };
  meta: Meta;
}

function useProviderStatistics() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  function fetchData() {
    return API.get<ProviderStatistics, undefined, false>({
      path: '/admin/statistics/providers',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<ProviderStatistics>, unknown, ProviderStatistics>(
    ['providerStatistics'],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 5,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

interface RevenueStatistics {
  result: {
    net_price: number;
    gross_price: number;
  };
  meta: Meta;
}

function useRevenueStatistics() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  function fetchData() {
    return API.get<RevenueStatistics, undefined, false>({
      path: '/admin/statistics/recurring-revenue',
      token,
      params: undefined,
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<AxiosResponse<RevenueStatistics>, unknown, RevenueStatistics>(
    ['revenueStatistics'],
    fetchData,
    {
      staleTime: Number.MAX_VALUE,
      enabled: !!token,
      retry: 5,
      select: ({ data }) => data,
      keepPreviousData: true,
    },
  );
}

interface OrganisationStatisticsParams {
  month: string;
}

function useOrganisationStatistics({ month }: OrganisationStatisticsParams) {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Params {
    month: string;
    format: 'default';
  }

  interface OrganisationStatisticsResults {
    result: Record<
      string,
      {
        total_spend: number;
        providers: Record<string, number>;
        revenue: number;
        active_users: number;
        total_users: number;
      }
    >;
    meta: Meta;
  }

  function fetchData() {
    return API.get<OrganisationStatisticsResults, Params, false>({
      path: '/admin/statistics/organisations',
      token,
      params: { month, format: 'default' },
      version: 1,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });
  }

  return useQuery<
    AxiosResponse<OrganisationStatisticsResults>,
    unknown,
    OrganisationStatisticsResults
  >(['organisationStatistics', { month }], fetchData, {
    staleTime: Number.MAX_VALUE,
    enabled: !!token,
    retry: 1,
    select: ({ data }) => data,
    keepPreviousData: false,
  });
}

function useOrganisationStatisticsCSV() {
  const { token } = useCognitoUser();
  const { addToast } = useToast();

  interface Params {
    month: string;
    format: 'csv';
  }

  async function organisationStatisticsCSV({ month }: OrganisationStatisticsParams) {
    const { data, headers } = await API.get<Blob, Params, true>({
      path: `/admin/statistics/organisations`,
      token,
      version: 1,
      params: { month, format: 'csv' },
      expectFile: true,
      onError: (error) => {
        addToast({ error, severity: 'error' });
      },
    });

    return Promise.resolve(createNamedBlob({ blob: data, headers }));
  }

  return useMutation<NamedBlob, unknown, OrganisationStatisticsParams>(
    (data: OrganisationStatisticsParams) => organisationStatisticsCSV(data),
  );
}

export {
  useConsumerStatistics,
  useOrganisationStatistics,
  useOrganisationStatisticsCSV,
  useProviderStatistics,
  useReceiptStatistics,
  useReservationStatistics,
  useRevenueStatistics,
};
