const englishTranslations = {
  general: {
    oops: 'Oops, something went wrong!',
    noRecords: 'No records',
    loading: 'Loading',
    pageNotFound: 'Page not found',
    open: 'Open',
  },
  loginPage: {
    verificationCodeMessage:
      'We have sent a verification code to $0. This code will be valid for 60 seconds.',
    verificationCode: 'Verification code',
    requestNewCodeProblems:
      'Any issues with your code? You can request a new code via the button below.',
    incorrectCode: "The code you've entered is incorrect",
    incorrectEmailOrPassword: 'Incorrect email or password',
    requestNewCode: 'Request new code',
    MFA: 'Multi-factor Authentication',
    confirm: 'Confirm',
    email: 'Email',
    password: 'Password',
    logIn: 'Log in',
  },
  mainPage: {
    showToasts: 'Show Toasts',
  },
  menu: {
    logout: 'Logout',
  },
  transactionsPage: {
    name: 'Name',
    transactionDate: 'Transaction data',
    amount: 'Amount',
    product: 'Product',
    description: 'Description',
    metaDescription: 'Meta description',
    priceExVat: 'Price Ex. VAT',
    priceIncVat: 'Price Inc. VAT',
    note: 'Note',
    status: 'Status',
    receipt: 'Receipt',
    reset: 'Reset',
    download: 'Download',
    pending: 'Pending',
    noReceiptAvailable: 'No receipt available',
    consumers: 'Consumers',
    all: 'All',
    organisation: 'Organisation',
    youngCapital: 'Young Capital',
    booking: 'Booking',
    sungevity: 'Sungevity',
    aethon: 'Aethon',
    budbee: 'Budbee',
    searchTransactions: 'Search transactions',
    transactions: 'Transactions',
  },
  transactionStatus: {
    pending: 'Pending',
    uploaded: 'Uploaded',
    skipped: 'Skipped',
    publicTransport: 'Public transport',
    activated: 'Activated',
    deactivated: 'Deactivated',
    invited: 'Invited',
    active: 'Active',
    closed: 'Closed',
    suspended: 'Suspended',
  },
  transactionPage: {
    title: 'Transaction by $0 on $1',
    transactionDetails: 'Transaction Details',
    fullName: 'Full Name',
    transactionDate: 'Transaction data',
    amount: 'Amount',
    product: 'Product',
    priceExVat: 'Price Excluding VAT',
    priceIncVat: 'Price Including VAT',
    note: 'Note',
    email: 'Email',
    type: 'Type',
    description: 'Description',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    provider: 'Provider',
    fare: 'Fare',
    additionalDetails: 'Additional details',
    metaDescription: 'Meta description',
    addMetaDescriptionData: 'Add meta description',
    updateMetaDescriptionData: 'Update meta description',
    update: 'Update',
    restore: 'Restore',
    status: 'Status',
    checkInCheckOut: '$0, $1',
    actions: 'Actions',
    resetToPending: 'Reset to Pending',
  },
  route: {
    login: 'Login',
    main: 'Main',
    statistics: 'Statistics',
    drivers: 'Drivers',
    driver: 'Driver',
    transaction: 'Transaction Details',
    transactions: 'Transactions',
    notFound: 'Not Found',
    metaRules: 'Meta Rules',
    configuration: 'Configuration',
    vouchers: 'Vouchers',
    voucher: 'Voucher',
    privacy: 'Privacy',
    reservation: 'Reservation',
    reservations: 'Reservations',
    travelCards: 'Travel Cards',
    product: 'Product',
    products: 'Products',
    organisation: 'Organisation',
    organisations: 'Organisations',
    pushNotifications: 'Push notifications',
    balanceAccounts: 'Balance accounts',
    community: 'Community',
    communities: 'Communities',
    invites: 'Invites',
  },
  metaRulesPage: {
    metaRules: 'Meta rules',
    restore: 'Restore',
    update: 'Update',
    description: 'Description',
    target: 'Target',
    transportationType: 'Transportation type',
    fuelType: 'Fuel type',
    pricePerKilometer: 'Price per kilometer',
  },
  configurationPage: {
    configuration: 'Configuration',
    status: 'Signup enabled',
    darkMode: 'Dark mode',
    restore: 'Restore',
    update: 'Update',
  },
  statisticsPage: {
    period: 'Period',
    organisation: 'Organisation',
    organisations: 'Organisations',
    consumers: 'Consumers',
    users: 'Users',
    providers: 'Providers',
    provider: 'Provider',
    numberOfUsers: 'Number of users',
    all: 'All',
    pastDays: 'Past $0 days',
    pending: 'Pending',
    skipped: 'Skipped',
    converted: 'Converted',
    completed: 'Completed',
    publicTransport: 'Public transport',
    singleGraph: 'Single graph',
    multipleGraphs: 'Multiple graphs',
    reservations: 'Reservations',
    receipts: 'Receipts',
    top10Days: 'Top 10 days of reservations',
    bottom10Days: 'Bottom 10 days of reservations',
    date: 'Date',
    amount: 'Amount',
    private: 'Private',
    business: 'Business',
    reservationTypes: 'Reservation types',
    cancelled: 'Cancelled',
    completedVsCancelled: 'Completed vs cancelled',
    completedReservations: 'Completed reservations',
    cancelledReservations: 'Cancelled reservations',
    check: 'Check',
    vecore: 'Vecore',
    felyx: 'Felyx',
    mywheels: 'MyWheels',
    greenwheels: 'Greenwheels',
    adyen: 'Adyen',
    mrr: 'MRR',
    forecasted: 'Forecasted',
    status: 'Status,',
    name: 'Name',
    value: 'Value',
    annualGrowthRate: 'Annual growth rate',
    monthlyGrowthRate: 'Monthly growth rate',
    total: 'Total',
    derivedData: 'Derived data',
    spendByProvider: 'Spend by provider',
    spend: 'Spend',
    type: 'Type',
    totalSpend: 'Total spend',
    monthlyRecurringRevenue: 'Monthly recurring revenue (MRR)',
    netRevenue: 'Net revenue (ex. VAT)',
    grossRevenue: 'Gross revenue (inc. VAT)',
    activeUsers: 'Active users',
    totalUsers: 'Total users',
    month: 'Month',
    exportCSV: 'Export CSV',
  },
  dualListEditor: {
    addMore: 'Add more',
    required: 'required',
  },
  driversPage: {
    name: 'Name',
    email: 'Email',
    phoneNumber: 'Phone number',
    accountCreatedOn: 'Account created on',
    organisation: 'Organisation',
    status: 'Status',
    exportAllDrivers: 'Export all drivers',
    syncGreenwheels: 'Sync Greenwheels',
    all: 'All',
    cancel: 'Cancel',
    cookie: 'Cookie',
    cookieExplanation:
      'Please login to the Greenwheels dashboard and copy the Cookie header from any graphql call in your browser inspector.',
    consumers: 'Consumers',
    searchDrivers: 'Search drivers',
    drivers: 'Drivers',
    areYouSure: 'Are you sure?',
    confirm: 'Confirm',
    successfullySyncedGreenwheels: 'Successfully synced Greenwheels',
  },
  driverPage: {
    details: 'Details',
    communities: 'Communities',
    wallet: 'Wallet',
    greenwheels: 'Greenwheels',
    mywheels: 'MyWheels',
    cardNumber: 'Card Number',
    driverId: 'Driver ID (Greenwheels)',
    providers: 'Providers',
    balance: 'Balance',
    reservations: 'Reservations',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phoneNumber: 'Phone number',
    address: 'Address',
    zipCode: 'Zip code',
    city: 'City',
    verified: 'Verified',
    addressIsVerified: 'Address is verified',
    addressIsNotVerified: 'Address is not verified',
    dateOfBirth: 'Date of birth',
    reference: 'Reference',
    startsOn: 'Starts on',
    endsOn: 'Ends on',
    accountDetails: 'Account details',
    personalInformation: 'Personal information',
    hasPaymentInformation: 'Has payment information',
    driversLicense: 'Drivers license',
    documentNumber: 'Document number',
    expiryDate: 'Expiry date',
    organisation: 'Organisation',
    organisationName: 'Organisation name',
    dasboardAccess: 'Dashboard access',
    '2FA': '2FA',
    '2FAEnabled': '2FA enabled',
    travelCard: 'Travel card',
    hasMyWheels: 'Has registered account',
    travelCardEnabled: 'Travel card enabled',
    communityName: 'Community name',
    fleets: 'Fleets',
    editable: 'Editable',
    yes: 'Yes',
    no: 'No',
    id: 'Id',
    travelCardId: 'Travel card id',
    status: 'Status',
    maskedNumber: 'Masked number',
    activate: 'Activate',
    suspend: 'Suspend',
    creditCardNumber: '**** **** **** $0',
    anonymizeAccount: 'Anonymize account',
    cancel: 'Cancel',
    confirm: 'Confirm',
    areYouSure: 'Are you sure?',
    anonymizeAccountMessage:
      'You are about to anonymize the account with email $0. This action cannot be ondone. Please enter the email address again to confirm.',
    successfullyAnonymizedAccount: 'Account successfully anonymized!',
    successfullyEditOrganisation: 'Organisation successfully edited!',
    successfullyEditPersonalInformation: 'Personal information successfully edited!',
    successfullyAddedWalletBalance: 'Successfully updated balance!',
    successfullyEditDetails: 'Details successfully edited!',
    successfullyAddDriversLicense: 'Drivers license successfully added!',
    restore: 'Restore',
    save: 'Save',
    acceptDriversLicense: 'Accept drivers license',
    AM: 'AM',
    B: 'B',
    issuedOn: 'Issued on',
    name: 'Name',
    driver: 'Driver',
    startDate: 'Start date',
    endDate: 'End date',
    price: 'Price',
    provider: 'Provider',
    type: 'Type',
    deactivate: 'Deactivate',
    dialogDeactivateTitle: 'Are you sure you want to set this driver to inactive?',
    dialogDeactivateMessage:
      "The driver won't be able to (un)lock the vehicle or end the reservation anymore. Please make sure there isn't an active reservation.",
    dialogDeactivateSuccess: 'Driver successfully deactivated!',
    dialogActivateTitle: 'Are you sure you want to set this driver to active?',
    dialogActivateSuccess: 'Driver successfully activated!',
    resendInvite: 'Resend invite',
    resendInviteSuccess: 'Invite successfully resent!',
    validatePayment: 'Validate payment',
    remitInvoices: 'Remit invoices',
    remitInvoicesSucces: 'Invoices are succesfully remitted!',
  },
  button: {
    actions: 'actions',
  },
  vouchersPage: {
    title: 'Voucher Templates',
    codePhrase: 'Code phrase',
    type: 'Type',
    value: 'Value',
    validFrom: 'Valid from',
    validTo: 'Valid To',
    maximumRides: 'Maximum rides',
    timesRedeemed: 'Times redeemed',
    searchTemplates: 'Search templates',
    all: 'All',
    discounts: 'Discounts',
    percentage: 'Percentage',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    deleteVouchersMessage: 'You are about to delete $0 vouchers. This action cannot be ondone.',
    deleteVoucherMessage: 'You are about to delete 1 voucher. This action cannot be ondone.',
    cancel: 'Cancel',
  },
  voucherPage: {
    codePhrase: 'Code phrase',
    type: 'Type',
    value: 'Value',
    discountCents: 'Discounts in cents',
    validFrom: 'Valid from',
    validTo: 'Valid To',
    maximumRides: 'Maximum rides',
    timesRedeemed: 'Times redeemed',
    save: 'Save',
    update: 'Update',
    cancel: 'Cancel',
    restore: 'Restore',
    successfullyAdded: 'Voucher successfully added!',
    successfullyEdited: 'Voucher successfully edited!',
    percentage: 'Percentage',
    discount: 'Discount',
    dialogTitle: 'Are you sure?',
    dialogMessage: 'You are about to set the discount to $0.',
    confirm: 'Confirm',
  },
  reservationPage: {
    cancelledOn: 'Cancelled on',
    startDate: 'Start date',
    endDate: 'End date',
    createdOn: 'Created on',
    startedOn: 'Started on',
    closedOn: 'Closed on',
    price: 'Price',
    newPrice: 'New price',
    status: 'Status',
    provider: 'Provider',
    type: 'Type',
    organisation: 'Organisation',
    vehicle: 'Vehicle',
    identifier: 'Identifier',
    externalIdentifier: 'External identifier',
    invoiceIdentifier: 'Invoice identifier',
    finalizedOn: 'Finalized on',
    licensePlate: 'License plate',
    lockVehicle: 'Lock vehicle',
    unlockVehicle: 'Unlock vehicle',
    closeReservation: 'Close reservation',
    cancelReservation: 'Cancel reservation',
    invoiceReservation: 'Invoice reservation',
    lockVehicleDialogTitle: 'Are you sure you want to lock the vehicle?',
    lockVehicleDialogMessage:
      'Make sure the driver is not driving the vehicle or present in the vehicle. Ask driver for confirmation afterwards.',
    lockVehicleSuccess: 'Vehicle locked',
    unlockVehicleDialogTitle: 'Are you sure you want to unlock the vehicle?',
    unlockVehicleDialogMessage:
      "Make sure the legitimate driver's at the vehicle to avoid unwarranted break-ins or fines.",
    unlockVehicleSucces: 'Vehicle unlocked',
    closeReservationDialogTitle: 'Are you sure you want to close the reservation?',
    closeReservationDialogMessage:
      '$0 can still open or close the reservation for a short while depending on the provider.',
    closeReservationSuccess: 'Reservation closed',
    cancelReservationDialogTitle: 'Are you sure you want to cancel the reservation?',
    cancelReservationDialogMessage:
      'User will not be able to access the vehicle, and may have to pay a cancellation fee if not cancelled far enough in advance.',
    cancelReservationSuccess: 'Reservation cancelled',
    invoiceReservationDialogTitle: 'Invoice this reservation',
    invoiceReservationSuccess: 'Reservation is invoiced',
    confirm: 'Confirm',
    cancel: 'Cancel',
    forceClose: 'Force close',
    forceCloseWarning:
      'WARNING! Force closing a reservation while it is still open at our partner will result in an endless ride and high costs! Only close when you are certain our partners have closed the reservation!',
    forceClosePleaseEnter: 'Please enter the email address again to confirm.',
    email: 'Email',
    restore: 'Restore',
    update: 'Update',
  },
  reservationsPage: {
    driver: 'Driver',
    organisation: 'Organisation',
    searchOrganisation: 'Search organisation',
    startDate: 'Start date',
    endDate: 'End date',
    completed: 'Completed',
    price: 'Price',
    status: 'Status',
    provider: 'Provider',
    type: 'Type',
    check: 'Check',
    felyx: 'Felyx',
    figo: 'Figo',
    greenwheels: 'Greenwheels',
    amber: 'Amber',
    myWheels: 'Mywheels',
    all: 'All',
    consumers: 'Consumers',
    youngCapital: 'Young Capital',
    booking: 'Booking',
    sungevity: 'Sungevity',
    aethon: 'Aethon',
    budbee: 'Budbee',
    private: 'Private',
    business: 'Business',
    future: 'Future',
    upcoming: 'Upcoming',
    active: 'Active',
    started: 'Started',
    ended: 'Ended',
    past: 'Past',
    cancelled: 'Cancelled',
    email: 'Email',
    name: 'Name',
    promotionalCode: 'Promo code',
    searchReservations: 'Search reservations',
    reservations: 'Reservations',
    exportReservations: 'Export reservations',
    cancel: 'Cancel',
    confirm: 'Confirm',
    true: 'True',
    false: 'False',
  },
  travelCardsPage: {
    brand: 'Brand',
    issuedOn: 'Issued on',
    maskedNumber: 'Masked number',
    name: 'Name',
    email: 'Email',
    organisation: 'Organistaion',
    status: 'Status',
    adyenId: 'Adyen id',
  },
  productsPage: {
    add: 'Add',
    name: 'Name',
    priceIncVAT: 'Price inc. VAT.',
    priceExlVAT: 'Price exl. VAT.',
    vatAmount: 'VAT amount',
    vatDescription: 'VAT description',
    vatPercentage: 'VAT percentage',
    addProduct: 'Add product',
  },
  productPage: {
    name: 'Name',
    priceExlVAT: 'Price exl. VAT.',
    VAT: 'VAT',
    cancel: 'Cancel',
    restore: 'Restore',
    save: 'Save',
    update: 'Update',
    successfullyAdded: 'Product successfully added',
    successfullyEdited: 'Product successfully edited',
    dialogTitle: 'Are you sure?',
    dialogMessage: 'You are about to set the price exl. VAT. to $0.',
    confirm: 'Confirm',
  },
  organisationsPage: {
    name: 'Name',
    contactEmailAddress: 'Contact email address',
    id: 'Id',
    searchOrganisations: 'Search organisations',
    organisations: 'Organisations',
    addOrganisation: 'Add organistation',
  },
  organisationPage: {
    organisation: 'Organisation',
    contact: 'Contact',
    commissions: 'Commissions',
    invoicing: 'Invoicing',
    impersonate: 'Impersonate organisation',
    openedLink: 'Link opened in a new tab',
    merchantCategoryCodes: 'Merchant Category Codes',
    connections: 'Connections',
    name: 'Name',
    contactEmailAddress: 'Contact email address',
    id: 'Id',
    KVK: 'KVK',
    VAT: 'VAT',
    stripeCustomerId: 'Stripe customer Id',
    communityId: 'Community Id',
    firstName: 'First Name',
    lastName: 'Last name',
    email: 'Email',
    phoneNumber: 'Phone number',
    reservationsNotificationsEmail: 'Email for upcoming reservation notifications',
    reference: 'Reference',
    active: 'Active for monthly invoicing',
    paymentToEmployee: 'Payment to employee',
    bookingTransactionNote: 'Booking/Transaction note',
    emissionReportAccess: 'Emission report access',
    travelCard: 'Travel card',
    kilometerRegistration: 'Kilometer registration',
    travelCardsByDefault: 'Travel cards by default',
    reservationsNotifications: 'Enable upcoming reservation notifications',
    outsideOfficeHours: 'Enable outside of office hours',
    topSpendingEmployees: 'Enable top spending employees',
    NSBusinessCards: 'Enable NS business cards',
    monthlyInvoicedProducts: 'Monthly invoiced products',
    resetDefaults: 'Reset defaults',
    addUser: 'Add user',
    addUsersInBulk: 'add users in Bulk',
    cancel: 'Cancel',
    save: 'Save',
    restore: 'Restore',
    update: 'Update',
    export: 'Export',
    dialogResendInvitesTitle: 'Are you sure you want to resend the invitations?',
    dialogResendInvitesMessage:
      "Make sure you don't do this too often. Not only will it impact our email score but might drive customers away.",
    dialogResendInvitesSuccess: 'Emails sent',
    confirm: 'Confirm',
    resendInvites: 'Resend invites',
    downloadBulkInvites: 'Download bulk invites',
    uploadUserXlsx: 'Upload user XLSX',
    uploadSuccessful: 'Upload successful!',
    connected: 'Connected',
    disconnected: 'Disconnected',
    editAfasConnection: 'Edit AFAS connection',
    dialogAFASConnectionTitle: 'Add AFAS connection',
    connectorName: 'Connector name',
    environment: 'Enviroment',
    environmentId: 'Enviroment id',
    token: 'Token',
    tokenPlaceHolder: '<token><version>x</version><data>Token</data></token>',
    test: 'Test',
    acceptance: 'Acceptance',
    production: 'Production',
    dialogAddUserSuccess: 'User successfully added!',
    add: 'Add',
    dialogAFASConnectionSuccess: 'AFAS coneection successfully edited!',
    editOrganisationSuccess: 'Organisation successfully edited!',
    editContactSuccess: 'Contact successfully edited!',
    editInvoicingSuccess: 'Invoicing successfully edited!',
    details: 'Details',
    providers: 'Providers',
    NSBusinessCard: 'NS Business Card',
    agreement: 'Agreement',
    department: 'Department',
    company: 'Company',
    providerUpdatedSuccesfully: 'Provider data successfully updated!',
    commissionsUpdatedSuccesfully: 'Commissions successfully updated!',
  },
  pushNotificationsPage: {
    title: 'Title ($0 / $1) characters',
    body: 'Body ($0 / $1) characters',
    uploadCSV: 'Upload CSV',
    send: 'Send',
    titleSingle: 'Title',
    bodySingle: 'Body',
    content: 'Content',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    dialogTitle: 'Are you sure you want send the following push notifaction to $0 users?',
    dialogSuccess: 'Push notifications successfully sent!',
    confirm: 'Confirm',
    cancel: 'Cancel',
    imported: 'Imported $0 users',
  },
  balanceAccountsPage: {
    name: 'Name',
    organisation: 'Organistaion',
    balance: 'Balance',
    reveal: 'Reveal',
    add: 'Add',
    close: 'Close',
    remove: 'Remove',
    dialogAddTitle: 'Adding funds to $0',
    dialogAddSuccess: 'Funds successfully added!',
    dialogRemoveTitle: 'Removing funds from to $0',
    dialogRemoveSuccess: 'Funds successfully removed!',
    amount: 'Amount in Euros',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseFillIn: 'Please fill in the organisation name to confirm.',
    funds: 'Funds',
    dialogWaitTitle: 'Please wait',
    dialogWaitMessage:
      "It takes a few minutes before clicking 'REVEAL' shows the new amount for $0.",
    ok: 'Ok',
    searchBalanceAccounts: 'Search balance accounts',
    balanceAccounts: 'balance accounts',
  },
  communitesPage: {
    communities: 'Communities',
    searchCommunities: 'Search communities',
    name: 'Name',
    size: 'Size',
    editable: 'Editable',
    addCommunity: 'Add community',
  },
  communityPage: {
    name: 'Name',
    cancel: 'Cance',
    restore: 'Restore',
    save: 'Save',
    update: 'Update',
    description: 'Description',
    fleets: 'Fleets',
    users: 'Users',
    successfullyAdded: 'Community successfully added',
    successfullyEdited: 'Community successfully edited',
    deleteCommunity: 'Delete community',
    confirm: 'Confirm',
    deleteCommunitySuccess: 'Community successfully deleted!',
    areYouSure: 'Are you sure?',
    dialogDeleteMessage:
      'You are about to delete the community $0. This action cannot be ondone. Please enter the community name again to confirm.',
    email: 'Email',
  },
  invitesPages: {
    name: 'Name',
    email: 'Email',
    code: 'Code',
    timesRedeemed: 'Times redeemed',
    timesUsedByInvitee: 'Times used by invitee',
    timesUsedByInviter: 'Times used by inviter',
    searchEmailOrCode: 'Search email or code',
    emailOrCode: 'Email or code',
    exportAllInivites: 'Export all invites',
  },
} as const;

// Add new languages here
// const dutchTranslations: typeof englishTranslations = {
//   loginPage: {
//     verificationCode: 'dit is Nederlands',
//     ...
//   },
//   mainPage: {
//     ...
//   },
// };

// Add new languages here
// type LocalizedContent = typeof englishTranslations | typeof dutchTranslations;
type LocalizedContent = typeof englishTranslations;

type CustomKeys3<A> = { [B in keyof A]: A[B] extends object ? A[B][keyof A[B]] : never };
type CustomString = CustomKeys3<LocalizedContent>[keyof CustomKeys3<LocalizedContent>];

// Add new languages here
// type Languages = 'english' | 'dutch;
type Languages = 'english';

export { type CustomString, englishTranslations, type Languages, type LocalizedContent };
